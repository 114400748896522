import type { HeroBlockV2 } from '../hero-block-v2'
import type { MediaBlock } from '../media-block'
import type { MultiHeroBlockV2 } from '../multi-hero-block-v2'
import type { ProductListBlockContent } from '../product-list-block'
import type { TextBlockV2 } from '../text-block-v2'
import type { TextMediaBlockV2 } from '../text-media-block-v2'
import type { SeoGroup } from '../seo-group'
import type { PromoBlock } from '../promo-block'
import type { AccordionContainerBlock } from '../accordion-container-block'
import type { NewsletterBlock } from '../newsletter-block'
import type { StoryblokContent } from '~/types/storyblok'
import type Storyblok from '~/types/vendors/storyblok'

const COMPONENT_NAME = 'landing-page'

export type LandingPageBlok = SeoGroup & StoryblokContent & {
  component: typeof COMPONENT_NAME
  widget?: string
  body: (
    | MediaBlock
    | HeroBlockV2
    | ProductListBlockContent
    | MultiHeroBlockV2
    | TextBlockV2
    | TextMediaBlockV2
    | NewsletterBlock
    | PromoBlock
    | AccordionContainerBlock
  )[]
}

export type LandingPageStory = Storyblok.Story<LandingPageBlok>

export function isStoryLandingPage(story: Storyblok.Story): story is LandingPageStory {
  return story.content.component === COMPONENT_NAME
}
